import './pollyfills';
import { createApp } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueClickAway from 'vue3-click-away';

// base classes
import './assets/sass/base.scss';

// element-plus
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';
import ElementPlusLocaleRu from 'element-plus/dist/locale/ru.mjs';

// plugins
import Notify from './plugins/notify';
import Notifications from '@kyvg/vue3-notification';
import initProgress from './plugins/progressbar';
import store from './store/index';
import router from './routes/index';
import auth from '@/plugins/auth';
import validate from '@/plugins/validate';

// app
import App from './App.vue';

initProgress(router);

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const app = createApp(App)
  .use(VueAxios, axios)
  .use(store)
  .use(ElementPlus, {
    locale: ElementPlusLocaleRu,
    size: 'large',
  })
  .use(VueClickAway)
  .use(Notify)
  .use(Notifications)
  .use(router)
  .use(auth);

app.provide('defaultLabelWidth', parseInt(process.env.VUE_APP_LABEL_WIDTH, 10));

app.mount('#app');
