import _isNull from 'lodash/isNull';

const formDataOperations = {
  data () {
    return {
      notifyText: ['Элемент', 'Элемента', 'Элементов'],
      item_id: null,
      loadModal: false,
      formDataChanged: false,
      formDataWatcher: null,
    };
  },

  computed: {
    editModalReady () {
      // следим за тем всё ли загружено для формы
      return true;
    },
  },

  created () {
    if (this.$route.params.id) {
      const itemId = { id: this.$route.params.id };
      this.getData(this.prepareGetData({ pageOptions: itemId }))
        .then((data) => {
          this.toggleEditModal('edit', data);
        })
        .catch((_response) => {
          this.$globNotify(this, 'warning', `${this.notifyText[0]} с таким id не найден!`);
        });
    }
  },

  methods: {
    addFormDataWatcher () {
      this.$nextTick(() => {
        this.formDataWatcher = this.$watch('formData', () => {
          this.formDataChanged = true;
        }, { deep: true });
        this.formDataChanged = false;
      });
    },

    deleteFormDataWatcher () {
      this.formDataChanged = false;
      if (typeof this.formDataWatcher === 'function') {
        this.formDataWatcher = null;
      }
    },

    toggleEditModal (actionType, item = null) {
      console.log('toggleEditModal',item)
      this.item_id = item ? item.id : null;
      this.showModal = true;
      this.loadModal = true;
      this.formDataChanged = false;
      this.modalSettings.actionType = actionType;
      this.waitModal = setInterval(() => {
        if (this.editModalReady) {
          clearInterval(this.waitModal);
          this.waitModal = null;
          this.setForm(item);
          this.$nextTick(() => {
            this.loadModal = false;
            this.addFormDataWatcher();
          });
        }
      }, 500);
    },

    prepareBoolean () {
      let result = null;
      if (this.formData) {
        result = { ...this.formData };
        for (const prop in result) {
          if (typeof result[prop] === 'boolean') {
            result[prop] = +result[prop];
          }
        }
      }
      return result;
    },

    prepareFormData () {
      console.log('prepareFormData')
      const resultData = {
        formData: {},
        token: this.$auth.token(),
      };
      for (const value in this.formData) {
        if (this.formData[value] instanceof Array) {
          if (this.formData[value].length) {
            resultData.formData[value] = this.formData[value];
          }
        } else {
          if (this.formData[value]) {
            resultData.formData[value] = this.formData[value];
          } else {
            if (typeof this.formData[value] === 'boolean' || this.formData[value] === 0) {
              resultData.formData[value] = this.formData[value];
            } else if (typeof this.formData[value] === 'string' || this.formData[value] === '') {
              resultData.formData[value] = '';
            }
          }
        }
      }
      resultData.formData.id = this.item_id;
      console.log('resultData',resultData)
      return resultData;
    },

    validate (options) {
      const settings = {
        closeModal: options === 'closeModal',
        actionType: this.modalSettings.actionType ? this.modalSettings.actionType : null,
      };
      this.$refs.form.validate().then((result) => {
        if (!result.valid) {
          this.showFirstError();
          if (process.env.NODE_ENV === 'development') {
            console.dir(result);
          }
          return false;
        }
        this.saveData(settings);
      });
    },

    showFirstError (form = null) {
      function openTab (el) {
        const tab = el.parentNode.closest('.el-tab-pane[aria-hidden="true"]');
        if (!tab) return;
        // try to find hidden tab in parents
        const index = Array.from(tab.parentNode.children).indexOf(tab);
        const tabItem = tab.closest('.el-tabs').querySelectorAll('.el-tabs__item')[index];
        if (tabItem) {
          // open tab with first error
          tabItem.dispatchEvent(new Event('click'));
        }
        return openTab(tab);
      }

      const element = form || this.$el;
      // search first error
      const firstError = element.querySelector('.el-form-item.is-error');
      if (firstError) {
        openTab(firstError);
        this.$nextTick(() => {
          this.scrollToIfNeed(firstError);
        });
      }
      this.$globNotify(this, 'warning', 'Не все поля заполнены!');
    },

    isInViewport (el, offset = 0) {
      if (_isNull(el)) return false;
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= offset &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },

    getScrollParent (el) {
      if (_isNull(el)) return window;
      if (el.scrollHeight > el.clientHeight) {
        return el;
      }
      return this.getScrollParent(el.parentNode);
    },

    scrollTo (el, offset = 80) {
      if (_isNull(el)) return;
      const container = this.getScrollParent(el.parentNode);
      container.scrollTo({
        top: (container.scrollTop ? container.scrollTop : 0) + el.getBoundingClientRect().y - offset,
        behavior: 'smooth',
      });
    },

    scrollToIfNeed (el, offset = 80) {
      if (_isNull(el) || this.isInViewport(el, offset)) return;
      this.scrollTo(el, offset);
    },
  },
};

export default formDataOperations;
