import { mapActions } from 'vuex';

const tinymceEditor = {
  data () {
    return {
      tinymceOptions: {
        width: '100%',
        plugins:
          'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
        toolbar_sticky: false,
        menubar: 'file edit view insert format tools table help',
        image_advtab: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        images_upload_handler: this.uploadFile,
        image_title: true,
        content_css: [
          'https://fonts.googleapis.com/css?family=Rubik:400,400i,700,700i&display=swap&subset=cyrillic',
          '//www.tiny.cloud/css/codepen.min.css',
        ],
        font_formats:
          'Rubik=Rubik; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Helvetica=helvetica;',
        fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
        fullpage_default_font_family: 'Rubik',
        extended_valid_elements:
          'script[language|src|async|defer|type|charset],iframe[src|style|width|height|scrolling|marginwidth|marginheight|frameborder]',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table',
        importcss_append: true,
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
      },
    };
  },

  methods: {
    ...mapActions({
      upload: 'upload_files/upload_file',
    }),

    uploadFile (blobInfo, progress) {
      const payload = {
        file: blobInfo.blob(),
      };
      return new Promise((resolve, reject) => {
        this.upload(this.prepareGetData({ payload: payload }))
          .then((response) => {
            if (response.location) {
              resolve(response.location);
            } else {
              this.$globNotify(this, 'error', 'Ошибка загрузки картинки!');
              reject(response);
            }
          })
          .catch((e) => {
            this.$globNotify(this, 'error', 'Ошибка загрузки картинки!');
            reject(e);
          });
      });
    },

    prepareGetData (options) {
      // taken from file 'mixins/itemsOperations.js' because we need only this method
      const resultData = {
        token: this.$auth.token(),
        pageOptions: {},
        payload: {},
      };
      if (options) {
        if (options.pageOptions) {
          for (const key in options.pageOptions) {
            resultData.pageOptions[key] = options.pageOptions[key];
          }
        }
        if (options.getAll === true) {
          resultData.pageOptions = { max: -1 };
        }
        if (options.payload) {
          for (const key in options.payload) {
            resultData.payload[key] = options.payload[key];
          }
        }
      }
      return resultData;
    },
  },
};

export default tinymceEditor;
